import React from 'react';

const MapIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
      <path
        fillRule="evenodd"
        d="M5 1C2.79086 1 1 2.79086 1 5V19C1 21.2091 2.79086 23 5 23H19C21.2091 23 23 21.2091 23 19V5C23 2.79086 21.2091 1 19 1H5ZM4.73333 1.73333C3.07648 1.73333 1.73333 3.07648 1.73333 4.73334V14.5667H11.2667V1.73333H4.73333ZM12 1.73333V14.5667V15.3H1.73333V17.8667H11.2667H12V22.2667H14.9333V14.5667H15.6667H22.2667V11.2667H15.6667H14.9333V1.73333H12ZM1.73333 18.6V19.2667C1.73333 20.9235 3.07648 22.2667 4.73334 22.2667H11.2667L11.2667 18.6H1.73333ZM15.6667 10.5333H22.2667V4.73333C22.2667 3.07648 20.9235 1.73333 19.2667 1.73333H15.6667V10.5333ZM22.2667 15.3H15.6667V22.2667H19.2667C20.9235 22.2667 22.2667 20.9235 22.2667 19.2667V15.3ZM3.02345 6.47793C3.02345 4.56018 4.583 3 6.5 3C8.417 3 9.97655 4.56018 9.97655 6.47793C9.97655 9.33528 6.84601 12.1252 6.71276 12.2426C6.65196 12.2961 6.576 12.3229 6.5 12.3229C6.424 12.3229 6.34804 12.2961 6.28727 12.2426C6.15396 12.1252 3.02345 9.33525 3.02345 6.47793ZM3.82345 6.5763C3.82345 8.61755 5.84487 10.7418 6.49845 11.3751C7.15203 10.7418 9.17345 8.61753 9.17345 6.5763C9.17345 5.1006 7.97344 3.9 6.49845 3.9C5.02345 3.9 3.82345 5.1006 3.82345 6.5763ZM6.5 5C5.67288 5 5 5.6729 5 6.5C5 7.32713 5.67288 8 6.5 8C7.32712 8 8 7.32713 8 6.5C8 5.6729 7.32712 5 6.5 5ZM6.5 7.3C6.05888 7.3 5.7 6.94113 5.7 6.5C5.7 6.0589 6.05888 5.7 6.5 5.7C6.94112 5.7 7.3 6.05887 7.3 6.5C7.3 6.94113 6.94112 7.3 6.5 7.3Z"
      />
    </svg>
  );
};

export default MapIcon;
