import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const LinkedInIcon = (props) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.7309 0H22.2691C23.2251 0 24 0.774902 24 1.7309V22.2691C24 23.2251 23.2251 24 22.2691 24H1.7309C0.774902 24 0 23.2251 0 22.2691V1.7309C0 0.774902 0.774902 0 1.7309 0ZM5.59039 18.1406H8.51294V9.34808H5.59039V18.1406ZM7.05176 8.14746H7.03271C6.052 8.14746 5.41772 7.47235 5.41772 6.6286C5.41772 5.76581 6.07141 5.10938 7.07117 5.10938C8.07092 5.10938 8.68616 5.76581 8.7052 6.6286C8.7052 7.47235 8.07092 8.14746 7.05176 8.14746ZM16.1288 18.1406H19.051V13.099C19.051 10.3984 17.6093 9.14172 15.6865 9.14172C14.1359 9.14172 13.441 9.9939 13.0527 10.593V9.34808H10.1303C10.1686 10.173 10.1303 18.1406 10.1303 18.1406H13.0527V13.2305C13.0527 12.9677 13.0715 12.7051 13.1488 12.5173C13.3601 11.9923 13.8409 11.4485 14.6483 11.4485C15.7057 11.4485 16.1288 12.2547 16.1288 13.4368V18.1406Z"
        fill="#127BB7"
      />
    </SvgIcon>
  );
};
export default LinkedInIcon;
