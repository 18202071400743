import React from 'react';

const EditIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
      <path
        fillRule="evenodd"
        d="M17.1344 1.32359C17.3605 1.1105 17.7165 1.12102 17.9296 1.34709L22.6345 6.33847C22.8475 6.56453 22.837 6.92053 22.611 7.13362C22.3849 7.3467 22.0289 7.33618 21.8158 7.11012L17.1109 2.11874C16.8979 1.89268 16.9084 1.53668 17.1344 1.32359Z"
      />
      <path
        fillRule="evenodd"
        d="M2.67916 15.764C2.90525 15.5509 3.26125 15.5615 3.47431 15.7876L8.17809 20.7789C8.39115 21.005 8.38059 21.361 8.15451 21.5741C7.92842 21.7871 7.57242 21.7766 7.35936 21.5505L2.65558 16.5591C2.44252 16.333 2.45308 15.977 2.67916 15.764Z"
      />
      <path
        fillRule="evenodd"
        d="M21.8869 2.19184C20.805 1.04378 19.0324 1.01647 17.9174 2.13005L3.55726 16.4768L1.61809 22.4054L7.47157 20.6671L21.8271 6.32599C22.9502 5.20343 22.9776 3.34921 21.8869 2.19184ZM17.1224 1.33407C18.6866 -0.228258 21.1895 -0.18861 22.7057 1.42027C24.2131 3.01985 24.1764 5.56841 22.6225 7.12165L22.6223 7.12177L8.16585 21.5637C8.09969 21.6298 8.01809 21.6784 7.92844 21.705L0.910147 23.7892C0.709658 23.8488 0.492689 23.7922 0.346846 23.6423C0.201003 23.4924 0.150368 23.2739 0.215386 23.0751L2.52985 15.9992C2.55736 15.9151 2.60432 15.8386 2.66691 15.7761L17.1224 1.33413L17.1224 1.33407Z"
      />
      <path
        fillRule="evenodd"
        d="M17.9182 4.20107C18.1378 4.42084 18.1376 4.777 17.9179 4.99657L7.07627 15.828C6.8565 16.0476 6.50035 16.0474 6.28078 15.8277C6.06121 15.6079 6.06138 15.2517 6.28115 15.0322L17.1227 4.2007C17.3425 3.98113 17.6987 3.9813 17.9182 4.20107Z"
      />
    </svg>
  );
};

export default EditIcon;
